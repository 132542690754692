<template>
  <nav>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <img src="https://longlen.net/img/logo2.png" width="48" alt=""> <b>Longlen.NET, Social Media Sandbox</b>
        </div>
        <div class="col-12 col-md-6" style="text-align: right;" v-if="this.$route.path==='/'">
          <router-link to="/"><button class="btn btn-primary">หน้าแรก</button></router-link>
          <router-link to="/student"><button class="btn btn-info">นักเรียน</button></router-link>
          <router-link to="/teacher"><button class="btn btn-warning">ผู้สอน</button></router-link>
        </div>
      </div>
    </div>
  </nav>
  <router-view/>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
    let fontAwesome = document.createElement('script');
    fontAwesome.setAttribute('src', 'https://kit.fontawesome.com/5b48c51d4b.js');
    fontAwesome.setAttribute('crossorigin', 'anonymous');
    document.head.appendChild(fontAwesome);

    /*let bscss = document.createElement('link')
    bscss.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css')
    bscss.setAttribute('rel', 'stylesheet')
    bscss.setAttribute('integrity', 'sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC')
    bscss.setAttribute('crossorigin', 'anonymous')
    document.head.appendChild(bscss)

    let bsjs = document.createElement('script')
    bsjs.setAttribute('src', 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js')
    bsjs.setAttribute('integrity', 'sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM')
    bsjs.setAttribute('crossorigin', 'anonymous')
    document.head.appendChild(bsjs)*/
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
