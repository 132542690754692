<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <span class="card-header" id="headingTwo">
          เว็บลองเล่น.เน็ต มีบทเรียน {{sum['totaltype']}} หมวด รวม {{sum['totallesson']}} บทเรียน เพื่อให้ผู้เรียนเท่าทันโลกโซเชียล และปรับตัวได้กับข้อมูลข่าวสารหลากรูปแบบ
        </span>
        <div class="card">
          <div class="card-body" style="text-align: center;">
            <div class="row">
              <div class="col-8 col-md-6 col-lg-4 col-xl-3" v-for="(lesson, index) in lessons" :key="index">
                <div class="card" style="width: 15rem;">
                  <img :src="'https://longlen.net/upload/'+lesson['post_file_link']" class="card-img-top">
                  <div class="card-body">
                    <h5 class="card-title">{{lesson['name']}}</h5>
                    <p class="card-text">{{lesson['objective'].substr(0,150)}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import axios from 'axios'
import router from '../router'

export default {
  name: 'homeView',
  components: {
  },
  data() {
    return {
      lessons:[],
      sum:[]
    }
  },
  methods: {
    redirectL(){
      router.push("/learn");
    }
  },
  mounted () {
    let formData = new FormData();
    formData.append('type', '1');
    axios
    .post('https://longlen.net/resapi/getFirstPage.php',formData)
    .then(response => {
      this.lessons=response.data;
    })
    formData.append('type', '2');
    axios
    .post('https://longlen.net/resapi/getSummary.php',formData)
    .then(response => {
      this.sum=response.data;
    })
  }
};
</script>

<style scoped lang="scss">

</style>