import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
//import { BootstrapVue } from 'bootstrap-vue'
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'
import  VueWindowModal  from  'vue-window-modal'
import { VueReCaptcha } from 'vue-recaptcha-v3'
//import ModalDialogs from 'vue-modal-dialogs'
//import * as ConfirmDialog from 'vuejs-confirm-dialog'


createApp(App)
.use(store)
.use(router)
.use(VueVideoPlayer)
.use(VueWindowModal)
.use(VueReCaptcha, { siteKey: '6LcD7zYkAAAAAP5gLqxJwN0h6CgEmfs1JhBzGpgS' })
//.use(ConfirmDialog)
//.use(ModalDialogs) 
//.use(BootstrapVue)
.mount('#app')




//const app = createApp()

//app.use(VueVideoPlayer)